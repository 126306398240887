import { Modal } from 'bootstrap'
import { defineComponent } from 'vue'
import { toggleModal } from '../ModalComponent.vue'
export default defineComponent({
  name: 'ConfirmationAlert',
  mounted() {
    this.$eventBus.$on('showConfirmationAlert', ({ title, message, confirmCallback, cancelCallback }) => {
      this.confirmCallback = confirmCallback
      this.cancelCallback = cancelCallback
      this.message = message
      this.title = title
      toggleModal('confirmAlertModal')
    })
  },
  data() {
    return {
      confirmAlertModal: null,
      confirmCallback: () => { },
      cancelCallback: () => { },
      title: null,
      message: null
    }
  },
  methods: {
    alertClosed() {
      this.cancelAction()
    },
    confirmAction() {
      this.confirmCallback()
      toggleModal('confirmAlertModal')
    },
    cancelAction() {
      if (this.cancelCallback) {
        this.cancelCallback()
      }
      toggleModal('confirmAlertModal')
    },
  },
  destroyed() {
  }
})
