export const languages: any = {
    "en": { name: "Inglês", flag: "🇺🇸" },
    "es": { name: "Espanhol", flag: "🇪🇸" },
    "fr": { name: "Francês", flag: "🇫🇷" },
    "de": { name: "Alemão", flag: "🇩🇪" },
    "it": { name: "Italiano", flag: "🇮🇹" },
    "pt-br": { name: "Brasil", flag: "🇧🇷" },
    "ru": { name: "Russo", flag: "🇷🇺" },
    "ja": { name: "Japonês", flag: "🇯🇵" },
    "zh-cn": { name: "Chinês Simplificado", flag: "🇨🇳" },
    "zh-tw": { name: "Chinês Tradicional", flag: "🇹🇼" },
    "ko": { name: "Coreano", flag: "🇰🇷" },
    "nl": { name: "Holandês", flag: "🇳🇱" },
    "sv": { name: "Sueco", flag: "🇸🇪" },
    "no": { name: "Norueguês", flag: "🇳🇴" },
    "da": { name: "Dinamarquês", flag: "🇩🇰" },
    "fi": { name: "Finlandês", flag: "🇫🇮" },
    "pl": { name: "Polonês", flag: "🇵🇱" },
    "el": { name: "Grego", flag: "🇬🇷" },
    "tr": { name: "Turco", flag: "🇹🇷" },
    "th": { name: "Tailandês", flag: "🇹🇭" },
};