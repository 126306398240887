<template>
  <div class="mb-3">
    <label v-if="label" class="form-label text-muted">
      {{ label }} <i v-if="required" class="text-danger">*</i>
    </label>
    <!-- <p class="form-label text-muted" v-if="label">{{ label }}</p> -->
    <div class="form-check" v-for="(item, index) in options" :key="componentKey + index">
      <input class="form-check-input" type="radio" :value="item.id" v-model="model.$model" :id="componentKey + index"
        @change="onChange($event)" />
      <label class="form-check-label text-primary" :for="componentKey + index">
        {{ item.title }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      required: false,
      default: () => { },
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    keyId: {
      type: String,
      default: 'custom-radio'
    }
  },
  computed: {
    componentKey() {
      return `option_${this.keyId}_`
    }
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event.target.value);
    },
  },
};
</script>
