import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    id: _ctx.settingsModalId,
    modalClass: 'modal-xl text-primary',
    title: _ctx.info.screenTitle
  }, {
    body: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: _ctx.text
          }, null, 8, _hoisted_1))
    ]),
    _: 1
  }, 8, ["id", "title"]))
}