<template>
  <nav class="navbar navbar-expand-lg navbar-light px-4 navbar-user" id="topbar">
    <div class="container-fluid">
      <router-link :to="$paths.home" class="navbar-brand">
        <img src="@/assets/img/navlogo.png" class="rounded" alt=""> <small class="text-muted">{{ $appString }}</small>
      </router-link>
      <template v-if="!userSession">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <router-link active-class="router-link-active" :to="$paths.login">
              <li class="nav-item">
                <a class="nav-link text-paragraph btn btn-sm btn-white fw-bold">Entrar</a>
              </li>
            </router-link>
          </ul>
        </div>
      </template>
      <template v-else>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <router-link v-for="link in links" active-class="router-link-active" :to="link.to">
              <li class="nav-item">
                <a class="nav-link text-paragraph">{{ link.title }}</a>
              </li>
            </router-link>
            <li class="nav-item d-block d-lg-none" @click.prevent="logout">
              <a class="nav-link text-paragraph text-danger cursor-pointer">Sair</a>
            </li>
          </ul>
          <div class="d-flex align-items-center d-none d-lg-flex">
            <span class="ps-3 pe-3 text-paragraph text-muted">|</span>
            <div class="dropdown cursor-pointer align-items-center d-flex" @mouseover="avatarOver"
              @mouseleave="avatarLeave">
              <span class="me-3 fw-bold text-primary">{{ userSession?.nickname }}</span>
              <a href="#" class="avatar avatar-sm dropdown-toggle">
                <div
                  class="avatar-img rounded-circle bg-secondary align-items-center avatar-img bg-secondary d-flex display-6 justify-content-center rounded-circle text-white">
                  <img class="w-100" :src="userSession?.formatted_image" alt="">
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end cursor-pointer">
                <span @click="logout" class="dropdown-item text-danger">Sair</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </nav>
</template>

<script>
import { useMainStore } from '@/store'
import $ from 'jquery'
import { storeToRefs } from 'pinia';
export default {
  name: 'Navbar',
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String
  },
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return {
      userSession: session,
    }
  },
  data() {
    return {
      links: [
        {
          to: this.$paths.appointments.list,
          title: 'Minhas sessões'
        },
        {
          to: this.$paths.chats,
          title: 'Chats'
        },
        {
          to: this.$paths.more,
          title: 'Mais'
        },
      ]
    }
  },
  methods: {
    async logout() {
      this.$router.push(this.$paths.login)
      // try {
      //   await this.$userService.logout()
      // } catch (error) {
      //   console.log('ERRO NO LOGOUT', error)
      // } finally {
      // }
    },
    avatarOver() {
      $('.dropdown-menu').addClass('show')
      $('.dropdown-menu').attr('data-bs-popper', true)
    },
    avatarLeave() {
      $('.dropdown-menu').removeClass('show')
      $('.dropdown-menu').removeAttr('data-bs-popper')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
