<template>
  
    <ModalComponent
      :title="title === null || title === undefined ? 'Confirmar ação' : title"
      id="confirmAlertModal"
      :modalClass="'text-primary'"
      @modalClosed="alertClosed"
    >
      <template v-slot:body>
        {{ message === null || message === undefined ? 'Tem certeza que deseja continuar ?' : message }}
      </template>
      <template v-slot:footer>
        <button type="button" @click="cancelAction" class="btn btn-outline-primary w-15">
          Não
        </button>
        <button type="button" @click="confirmAction" class="btn btn-primary w-15">
          Sim
        </button>
      </template>
    </ModalComponent>
  
</template>
<script lang="ts">
import ConfirmationAlertController from './ConfirmationAlertController'
export default ConfirmationAlertController
</script>
<style scoped></style>
