const getChild = (params: string) => params
const paths = {
    not_found: '/not-found',
    home: '/home',
    login: '/login',
    psiDetail: '/psi',
    scheduleDate: '/data-da-sessao',
    callScreen: '/sessao-live',
    checkout: '/checkout',
    custom_schedule: '/horario-avulso',
    more: '/mais',
    passwordRecovery: '/recuperar-senha',
    passwordReset: '/redefinir-senha',
    settings: '/aceites',
    searchPsi: '/buscar-psi',
    benefits: '/mais/beneficios',
    chats: '/chats',
    appointments: {
        list: '/sessoes',
        detail: '/sessoes/detalhes'
    },
    register: {
        base: '/cadastro',
        info: '/cadastro/informacoes',
        nickname: '/cadastro/apelido',
        children: {
            info: 'informacoes',
            nickname: 'apelido',
        }
    }
}

export default paths