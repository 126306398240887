import { toggleModal } from '@/components/ModalComponent.vue';
import PaymentService from '@/services/PaymentService';
import { EventBus } from '@/utils/EventBus';
import { loadStripe } from '@stripe/stripe-js';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { StripeElement, StripeElements } from 'vue-stripe-js';

export default defineComponent({
    setup() {
        const stripeKey = ref(process.env.VUE_APP_STRIPE_PUBLISH_KEY)
        const instanceOptions = ref({
            // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
            // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        })
        const stripeLoaded = ref(false)
        const elms = ref()
        const cardNumberOptions = ref({})
        const cardExpiryOptions = ref({})
        const cardCvcOptions = ref({})
        const cardNumber = ref()
        const cardExpiry = ref()
        const cardCvc = ref()
        const addCardModalId = ref('add-credit-cards')
        let submitting = ref(false)

        onBeforeMount(() => {
            const stripePromise = loadStripe(stripeKey.value)
            stripePromise.then(() => {
                stripeLoaded.value = true
            }).catch(e => console.log(e))
        })

        const handleAddCreditCard = () => {
            submitting.value = true
            // Get stripe element
            const cn = cardNumber.value.stripeElement
            const ce = cardExpiry.value.stripeElement
            const cc = cardCvc.value.stripeElement

            // Access instance methods, e.g. createToken()
            elms.value.instance.createToken({ ...cn, ...ce, ...cc })
                .then(async (result: any) => {
                    // Handle result.error or result.token
                    const paymentService = new PaymentService()
                    if (result.error) {
                        EventBus.$emit('showErrorToast', { msg: paymentService.getCardErrorMessage(result.error.stripeErrorCode) })
                    }
                    await paymentService.createCreditCard(result.token.id)
                    paymentService.setFavoriteCard({
                        card_id: result.token.card.id,
                        last_digits: result.token.card.last4
                    })
                    EventBus.$emit('onCreditCardAdded')
                    EventBus.$emit('showToast', { title: 'Novo cartão adicionado', msg: 'Cartão de crédito adicionado com sucesso' })
                    cn.clear()
                    ce.clear()
                    cc.clear()
                    toggleModal('add-credit-cards')
                })
                .catch((error: any) => {
                    console.log(error)
                    EventBus.$emit('showErrorToast', { msg: error?.message })
                })
                .finally(() => {
                    submitting.value = false
                })
        }

        return {
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            elms,
            handleAddCreditCard,
            cardNumberOptions,
            cardExpiryOptions,
            cardCvcOptions,
            cardNumber,
            cardExpiry,
            cardCvc,
            submitting,
            addCardModalId
        }
    },
    components: {
        StripeElements,
        StripeElement,
    },
})