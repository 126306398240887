export const phoneCodes = [{
  code: "BR",
  dial_code: "+55",
  flag: "🇧🇷",
  mask: "(99)99999-9999",
  name: "Brasil"
}, {
  code: "US",
  dial_code: "+1",
  flag: "🇺🇸",
  mask: "(999)999-9999",
  name: "United States"
}, {
  code: "AF",
  dial_code: "+93",
  flag: "🇦🇫",
  mask: "99-999-9999",
  name: "Afghanistan"
}, {
  code: "AL",
  dial_code: "+355",
  flag: "🇦🇱",
  mask: "(999)999-999",
  name: "Albania"
}, {
  code: "DZ",
  dial_code: "+213",
  flag: "🇩🇿",
  mask: "99-999-9999",
  name: "Algeria"
}, {
  code: "AS",
  dial_code: "+1684",
  flag: "🇦🇸",
  mask: "999-9999",
  name: "American Samoa"
}, {
  code: "AD",
  dial_code: "+376",
  flag: "🇦🇩",
  mask: "999-999",
  name: "Andorra"
}, {
  code: "AO",
  dial_code: "+244",
  flag: "🇦🇴",
  mask: "(999)999-999",
  name: "Angola"
}, {
  code: "AI",
  dial_code: "+1264",
  flag: "🇦🇮",
  mask: "999-9999",
  name: "Anguilla"
}, {
  code: "AQ",
  dial_code: "+672",
  flag: "🇦🇶",
  mask: "199-999",
  name: "Antarctica"
}, {
  code: "AG",
  dial_code: "+1268",
  flag: "🇦🇬",
  mask: "999-9999",
  name: "Antigua and Barbuda"
}, {
  code: "AR",
  dial_code: "+54",
  flag: "🇦🇷",
  mask: "(999)999-9999",
  name: "Argentina"
}, {
  code: "AM",
  dial_code: "+374",
  flag: "🇦🇲",
  mask: "99-999-999",
  name: "Armenia"
}, {
  code: "AW",
  dial_code: "+297",
  flag: "🇦🇼",
  mask: "999-9999",
  name: "Aruba"
}, {
  code: "AU",
  dial_code: "+61",
  flag: "🇦🇺",
  mask: "9-9999-9999",
  name: "Australia"
}, {
  code: "AT",
  dial_code: "+43",
  flag: "🇦🇹",
  mask: "(999)999-9999",
  name: "Austria"
}, {
  code: "AZ",
  dial_code: "+994",
  flag: "🇦🇿",
  mask: "99-999-99-99",
  name: "Azerbaijan"
}, {
  code: "BS",
  dial_code: "+1242",
  flag: "🇧🇸",
  mask: "999-9999",
  name: "Bahamas"
}, {
  code: "BH",
  dial_code: "+973",
  flag: "🇧🇭",
  mask: "9999-9999",
  name: "Bahrain"
}, {
  code: "BD",
  dial_code: "+880",
  flag: "🇧🇩",
  mask: "99-999-999",
  name: "Bangladesh"
}, {
  code: "BB",
  dial_code: "+1246",
  flag: "🇧🇧",
  mask: "999-9999",
  name: "Barbados"
}, {
  code: "BY",
  dial_code: "+375",
  flag: "🇧🇾",
  mask: "(99)999-99-99",
  name: "Belarus"
}, {
  code: "BE",
  dial_code: "+32",
  flag: "🇧🇪",
  mask: "(999)999-999",
  name: "Belgium"
}, {
  code: "BZ",
  dial_code: "+501",
  flag: "🇧🇿",
  mask: "999-9999",
  name: "Belize"
}, {
  code: "BJ",
  dial_code: "+229",
  flag: "🇧🇯",
  mask: "99-99-9999",
  name: "Benin"
}, {
  code: "BM",
  dial_code: "+1441",
  flag: "🇧🇲",
  mask: "999-9999",
  name: "Bermuda"
}, {
  code: "BT",
  dial_code: "+975",
  flag: "🇧🇹",
  mask: "9-999-999",
  name: "Bhutan"
}, {
  code: "BO",
  dial_code: "+591",
  flag: "🇧🇴",
  mask: "9-999-9999",
  name: "Bolivia, Plurinational State of bolivia"
}, {
  code: "BA",
  dial_code: "+387",
  flag: "🇧🇦",
  mask: "99-9999",
  name: "Bosnia and Herzegovina"
}, {
  code: "BW",
  dial_code: "+267",
  flag: "🇧🇼",
  mask: "99-999-999",
  name: "Botswana"
}, {
  code: "IO",
  dial_code: "+246",
  flag: "🇮🇴",
  mask: "999-9999",
  name: "British Indian Ocean Territory"
}, {
  code: "BN",
  dial_code: "+673",
  flag: "🇧🇳",
  mask: "999-9999",
  name: "Brunei Darussalam"
}, {
  code: "BG",
  dial_code: "+359",
  flag: "🇧🇬",
  mask: "(999)999-999",
  name: "Bulgaria"
}, {
  code: "BF",
  dial_code: "+226",
  flag: "🇧🇫",
  mask: "99-99-9999",
  name: "Burkina Faso"
}, {
  code: "BI",
  dial_code: "+257",
  flag: "🇧🇮",
  mask: "99-99-9999",
  name: "Burundi"
}, {
  code: "KH",
  dial_code: "+855",
  flag: "🇰🇭",
  mask: "99-999-999",
  name: "Cambodia"
}, {
  code: "CM",
  dial_code: "+237",
  flag: "🇨🇲",
  mask: "9999-9999",
  name: "Cameroon"
}, {
  code: "CA",
  dial_code: "+1",
  flag: "🇨🇦",
  mask: "(999)999-9999",
  name: "Canada"
}, {
  code: "CV",
  dial_code: "+238",
  flag: "🇨🇻",
  mask: "(999)99-99",
  name: "Cape Verde"
}, {
  code: "KY",
  dial_code: "+345",
  flag: "🇰🇾",
  mask: "999-9999",
  name: "Cayman Islands"
}, {
  code: "CF",
  dial_code: "+236",
  flag: "🇨🇫",
  mask: "99-99-9999",
  name: "Central African Republic"
}, {
  code: "TD",
  dial_code: "+235",
  flag: "🇹🇩",
  mask: "99-99-99-99",
  name: "Chad"
}, {
  code: "CL",
  dial_code: "+56",
  flag: "🇨🇱",
  mask: "9-9999-9999",
  name: "Chile"
}, {
  code: "CN",
  dial_code: "+86",
  flag: "🇨🇳",
  mask: "99-99999-99999",
  name: "China"
}, {
  code: "CO",
  dial_code: "+57",
  flag: "🇨🇴",
  mask: "(999)999-9999",
  name: "Colombia"
}, {
  code: "KM",
  dial_code: "+269",
  flag: "🇰🇲",
  mask: "99-99999",
  name: "Comoros"
}, {
  code: "CG",
  dial_code: "+242",
  flag: "🇨🇬",
  mask: "99-999-9999",
  name: "Congo"
}, {
  code: "CD",
  dial_code: "+243",
  flag: "🇨🇩",
  mask: "(999)999-999",
  name: "Congo, The Democratic Republic of the Congo"
}, {
  code: "CK",
  dial_code: "+682",
  flag: "🇨🇰",
  mask: "99-999",
  name: "Cook Islands"
}, {
  code: "CR",
  dial_code: "+506",
  flag: "🇨🇷",
  mask: "9999-9999",
  name: "Costa Rica"
}, {
  code: "CI",
  dial_code: "+225",
  flag: "🇨🇮",
  mask: "99-999-999",
  name: "Cote d'Ivoire"
}, {
  code: "HR",
  dial_code: "+385",
  flag: "🇭🇷",
  mask: "99-999-999",
  name: "Croatia"
}, {
  code: "CU",
  dial_code: "+53",
  flag: "🇨🇺",
  mask: "9-999-9999",
  name: "Cuba"
}, {
  code: "CY",
  dial_code: "+357",
  flag: "🇨🇾",
  mask: "99-999-999",
  name: "Cyprus"
}, {
  code: "CZ",
  dial_code: "+420",
  flag: "🇨🇿",
  mask: "(999)999-999",
  name: "Czech Republic"
}, {
  code: "DK",
  dial_code: "+45",
  flag: "🇩🇰",
  mask: "99-99-99-99",
  name: "Denmark"
}, {
  code: "DJ",
  dial_code: "+253",
  flag: "🇩🇯",
  mask: "99-99-99-99",
  name: "Djibouti"
}, {
  code: "DM",
  dial_code: "+1767",
  flag: "🇩🇲",
  mask: "999-9999",
  name: "Dominica"
}, {
  code: "DO",
  dial_code: "+1849",
  flag: "🇩🇴",
  mask: "999-9999",
  name: "Dominican Republic"
}, {
  code: "EC",
  dial_code: "+593",
  flag: "🇪🇨",
  mask: "9-999-9999",
  name: "Ecuador"
}, {
  code: "EG",
  dial_code: "+20",
  flag: "🇪🇬",
  mask: "(999)999-9999",
  name: "Egypt"
}, {
  code: "SV",
  dial_code: "+503",
  flag: "🇸🇻",
  mask: "99-99-9999",
  name: "El Salvador"
}, {
  code: "GQ",
  dial_code: "+240",
  flag: "🇬🇶",
  mask: "99-999-9999",
  name: "Equatorial Guinea"
}, {
  code: "ER",
  dial_code: "+291",
  flag: "🇪🇷",
  mask: "9-999-999",
  name: "Eritrea"
}, {
  code: "EE",
  dial_code: "+372",
  flag: "🇪🇪",
  mask: "999-9999",
  name: "Estonia"
}, {
  code: "ET",
  dial_code: "+251",
  flag: "🇪🇹",
  mask: "99-999-9999",
  name: "Ethiopia"
}, {
  code: "FK",
  dial_code: "+500",
  flag: "🇫🇰",
  mask: "99999",
  name: "Falkland Islands (Malvinas)"
}, {
  code: "FO",
  dial_code: "+298",
  flag: "🇫🇴",
  mask: "999-999",
  name: "Faroe Islands"
}, {
  code: "FJ",
  dial_code: "+679",
  flag: "🇫🇯",
  mask: "99-99999",
  name: "Fiji"
}, {
  code: "FI",
  dial_code: "+358",
  flag: "🇫🇮",
  mask: "(999)999-99-99",
  name: "Finland"
}, {
  code: "FR",
  dial_code: "+33",
  flag: "🇫🇷",
  mask: "(999)999-999",
  name: "France"
}, {
  code: "GF",
  dial_code: "+594",
  flag: "🇬🇫",
  mask: "99999-9999",
  name: "French Guiana"
}, {
  code: "PF",
  dial_code: "+689",
  flag: "🇵🇫",
  mask: "99-99-99",
  name: "French Polynesia"
}, {
  code: "GA",
  dial_code: "+241",
  flag: "🇬🇦",
  mask: "9-99-99-99",
  name: "Gabon"
}, {
  code: "GM",
  dial_code: "+220",
  flag: "🇬🇲",
  mask: "(999)99-99",
  name: "Gambia"
}, {
  code: "GE",
  dial_code: "+995",
  flag: "🇬🇪",
  mask: "(999)999-999",
  name: "Georgia"
}, {
  code: "DE",
  dial_code: "+49",
  flag: "🇩🇪",
  mask: "999-999",
  name: "Germany"
}, {
  code: "GH",
  dial_code: "+233",
  flag: "🇬🇭",
  mask: "(999)999-999",
  name: "Ghana"
}, {
  code: "GI",
  dial_code: "+350",
  flag: "🇬🇮",
  mask: "999-99999",
  name: "Gibraltar"
}, {
  code: "GR",
  dial_code: "+30",
  flag: "🇬🇷",
  mask: "(999)999-9999",
  name: "Greece"
}, {
  code: "GL",
  dial_code: "+299",
  flag: "🇬🇱",
  mask: "99-99-99",
  name: "Greenland"
}, {
  code: "GD",
  dial_code: "+1473",
  flag: "🇬🇩",
  mask: "999-9999",
  name: "Grenada"
}, {
  code: "GU",
  dial_code: "+1671",
  flag: "🇬🇺",
  mask: "999-9999",
  name: "Guam"
}, {
  code: "GT",
  dial_code: "+502",
  flag: "🇬🇹",
  mask: "9-999-9999",
  name: "Guatemala"
}, {
  code: "GN",
  dial_code: "+224",
  flag: "🇬🇳",
  mask: "99-999-999",
  name: "Guinea"
}, {
  code: "GW",
  dial_code: "+245",
  flag: "🇬🇼",
  mask: "9-999999",
  name: "Guinea-Bissau"
}, {
  code: "GY",
  dial_code: "+592",
  flag: "🇬🇾",
  mask: "999-9999",
  name: "Guyana"
}, {
  code: "HT",
  dial_code: "+509",
  flag: "🇭🇹",
  mask: "99-99-9999",
  name: "Haiti"
}, {
  code: "VA",
  dial_code: "+379",
  flag: "🇻🇦",
  mask: "6-698-99999",
  name: "Holy See (Vatican City State)"
}, {
  code: "HN",
  dial_code: "+504",
  flag: "🇭🇳",
  mask: "9999-9999",
  name: "Honduras"
}, {
  code: "HK",
  dial_code: "+852",
  flag: "🇭🇰",
  mask: "9999-9999",
  name: "Hong Kong"
}, {
  code: "HU",
  dial_code: "+36",
  flag: "🇭🇺",
  mask: "(999)999-999",
  name: "Hungary"
}, {
  code: "IS",
  dial_code: "+354",
  flag: "🇮🇸",
  mask: "999-9999",
  name: "Iceland"
}, {
  code: "IN",
  dial_code: "+91",
  flag: "🇮🇳",
  mask: "(9999)999-999",
  name: "India"
}, {
  code: "ID",
  dial_code: "+62",
  flag: "🇮🇩",
  mask: "(899)999-99-999",
  name: "Indonesia"
}, {
  code: "IR",
  dial_code: "+98",
  flag: "🇮🇷",
  mask: "(999)999-9999",
  name: "Iran, Islamic Republic of Persian Gulf"
}, {
  code: "IQ",
  dial_code: "+964",
  flag: "🇮🇶",
  mask: "(999)999-9999",
  name: "Iraq"
}, {
  code: "IE",
  dial_code: "+353",
  flag: "🇮🇪",
  mask: "(999)999-999",
  name: "Ireland"
}, {
  code: "IL",
  dial_code: "+972",
  flag: "🇮🇱",
  mask: "9-999-9999",
  name: "Israel"
}, {
  code: "IT",
  dial_code: "+39",
  flag: "🇮🇹",
  mask: "(999)9999-999",
  name: "Italy"
}, {
  code: "JM",
  dial_code: "+1876",
  flag: "🇯🇲",
  mask: "999-9999",
  name: "Jamaica"
}, {
  code: "JP",
  dial_code: "+81",
  flag: "🇯🇵",
  mask: "(999)999-999",
  name: "Japan"
}, {
  code: "JO",
  dial_code: "+962",
  flag: "🇯🇴",
  mask: "9-9999-9999",
  name: "Jordan"
}, {
  code: "KZ",
  dial_code: "+7",
  flag: "🇰🇿",
  mask: "(799)999-99-99",
  name: "Kazakhstan"
}, {
  code: "KE",
  dial_code: "+254",
  flag: "🇰🇪",
  mask: "999-999999",
  name: "Kenya"
}, {
  code: "KI",
  dial_code: "+686",
  flag: "🇰🇮",
  mask: "99-999",
  name: "Kiribati"
}, {
  code: "KP",
  dial_code: "+850",
  flag: "🇰🇵",
  mask: "9999-9999999999999",
  name: "Korea, Democratic People's Republic of Korea"
}, {
  code: "KR",
  dial_code: "+82",
  flag: "🇰🇷",
  mask: "99-999-9999",
  name: "Korea, Republic of South Korea"
}, {
  code: "KW",
  dial_code: "+965",
  flag: "🇰🇼",
  mask: "9999-9999",
  name: "Kuwait"
}, {
  code: "KG",
  dial_code: "+996",
  flag: "🇰🇬",
  mask: "(999)999-999",
  name: "Kyrgyzstan"
}, {
  code: "LA",
  dial_code: "+856",
  flag: "🇱🇦",
  mask: "99-999-999",
  name: "Laos"
}, {
  code: "LV",
  dial_code: "+371",
  flag: "🇱🇻",
  mask: "99-999-999",
  name: "Latvia"
}, {
  code: "LB",
  dial_code: "+961",
  flag: "🇱🇧",
  mask: "9-999-999",
  name: "Lebanon"
}, {
  code: "LS",
  dial_code: "+266",
  flag: "🇱🇸",
  mask: "9-999-9999",
  name: "Lesotho"
}, {
  code: "LR",
  dial_code: "+231",
  flag: "🇱🇷",
  mask: "99-999-999",
  name: "Liberia"
}, {
  code: "LY",
  dial_code: "+218",
  flag: "🇱🇾",
  mask: "21-999-9999",
  name: "Libyan Arab Jamahiriya"
}, {
  code: "LI",
  dial_code: "+423",
  flag: "🇱🇮",
  mask: "(999)999-9999",
  name: "Liechtenstein"
}, {
  code: "LT",
  dial_code: "+370",
  flag: "🇱🇹",
  mask: "(999)99-999",
  name: "Lithuania"
}, {
  code: "LU",
  dial_code: "+352",
  flag: "🇱🇺",
  mask: "(999)999-999",
  name: "Luxembourg"
}, {
  code: "MO",
  dial_code: "+853",
  flag: "🇲🇴",
  mask: "9999-9999",
  name: "Macao"
}, {
  code: "MK",
  dial_code: "+389",
  flag: "🇲🇰",
  mask: "99-999-999",
  name: "Macedonia"
}, {
  code: "MG",
  dial_code: "+261",
  flag: "🇲🇬",
  mask: "99-99-99999",
  name: "Madagascar"
}, {
  code: "MW",
  dial_code: "+265",
  flag: "🇲🇼",
  mask: "9-9999-9999",
  name: "Malawi"
}, {
  code: "MY",
  dial_code: "+60",
  flag: "🇲🇾",
  mask: "9-999-999",
  name: "Malaysia"
}, {
  code: "MV",
  dial_code: "+960",
  flag: "🇲🇻",
  mask: "999-9999",
  name: "Maldives"
}, {
  code: "ML",
  dial_code: "+223",
  flag: "🇲🇱",
  mask: "99-99-9999",
  name: "Mali"
}, {
  code: "MT",
  dial_code: "+356",
  flag: "🇲🇹",
  mask: "9999-9999",
  name: "Malta"
}, {
  code: "MH",
  dial_code: "+692",
  flag: "🇲🇭",
  mask: "999-9999",
  name: "Marshall Islands"
}, {
  code: "MQ",
  dial_code: "+596",
  flag: "🇲🇶",
  mask: "(999)99-99-99",
  name: "Martinique"
}, {
  code: "MR",
  dial_code: "+222",
  flag: "🇲🇷",
  mask: "99-99-9999",
  name: "Mauritania"
}, {
  code: "MU",
  dial_code: "+230",
  flag: "🇲🇺",
  mask: "999-9999",
  name: "Mauritius"
}, {
  code: "MX",
  dial_code: "+52",
  flag: "🇲🇽",
  mask: "99-99-9999",
  name: "Mexico"
}, {
  code: "FM",
  dial_code: "+691",
  flag: "🇫🇲",
  mask: "999-9999",
  name: "Micronesia, Federated States of Micronesia"
}, {
  code: "MD",
  dial_code: "+373",
  flag: "🇲🇩",
  mask: "9999-9999",
  name: "Moldova"
}, {
  code: "MC",
  dial_code: "+377",
  flag: "🇲🇨",
  mask: "99-999-999",
  name: "Monaco"
}, {
  code: "MN",
  dial_code: "+976",
  flag: "🇲🇳",
  mask: "99-99-9999",
  name: "Mongolia"
}, {
  code: "ME",
  dial_code: "+382",
  flag: "🇲🇪",
  mask: "99-999-999",
  name: "Montenegro"
}, {
  code: "MS",
  dial_code: "+1664",
  flag: "🇲🇸",
  mask: "999-9999",
  name: "Montserrat"
}, {
  code: "MA",
  dial_code: "+212",
  flag: "🇲🇦",
  mask: "99-9999-999",
  name: "Morocco"
}, {
  code: "MZ",
  dial_code: "+258",
  flag: "🇲🇿",
  mask: "99-999-999",
  name: "Mozambique"
}, {
  code: "MM",
  dial_code: "+95",
  flag: "🇲🇲",
  mask: "999-999",
  name: "Myanmar"
}, {
  code: "NA",
  dial_code: "+264",
  flag: "🇳🇦",
  mask: "99-999-9999",
  name: "Namibia"
}, {
  code: "NR",
  dial_code: "+674",
  flag: "🇳🇷",
  mask: "999-9999",
  name: "Nauru"
}, {
  code: "NP",
  dial_code: "+977",
  flag: "🇳🇵",
  mask: "99-999-999",
  name: "Nepal"
}, {
  code: "NL",
  dial_code: "+31",
  flag: "🇳🇱",
  mask: "99-999-9999",
  name: "Netherlands"
}, {
  code: "AN",
  dial_code: "+599",
  flag: "",
  mask: "9999-9999",
  name: "Netherlands Antilles"
}, {
  code: "NC",
  dial_code: "+687",
  flag: "🇳🇨",
  mask: "99-9999",
  name: "New Caledonia"
}, {
  code: "NZ",
  dial_code: "+64",
  flag: "🇳🇿",
  mask: "(999)999-9999",
  name: "New Zealand"
}, {
  code: "NI",
  dial_code: "+505",
  flag: "🇳🇮",
  mask: "9999-9999",
  name: "Nicaragua"
}, {
  code: "NE",
  dial_code: "+227",
  flag: "🇳🇪",
  mask: "99-99-9999",
  name: "Niger"
}, {
  code: "NG",
  dial_code: "+234",
  flag: "🇳🇬",
  mask: "(999)999-9999",
  name: "Nigeria"
}, {
  code: "NU",
  dial_code: "+683",
  flag: "🇳🇺",
  mask: "9999",
  name: "Niue"
}, {
  code: "NF",
  dial_code: "+672",
  flag: "🇳🇫",
  mask: "399-999",
  name: "Norfolk Island"
}, {
  code: "MP",
  dial_code: "+1670",
  flag: "🇲🇵",
  mask: "999-9999",
  name: "Northern Mariana Islands"
}, {
  code: "NO",
  dial_code: "+47",
  flag: "🇳🇴",
  mask: "(999)99-999",
  name: "Norway"
}, {
  code: "OM",
  dial_code: "+968",
  flag: "🇴🇲",
  mask: "99-999-999",
  name: "Oman"
}, {
  code: "PK",
  dial_code: "+92",
  flag: "🇵🇰",
  mask: "(999)999-9999",
  name: "Pakistan"
}, {
  code: "PW",
  dial_code: "+680",
  flag: "🇵🇼",
  mask: "999-9999",
  name: "Palau"
}, {
  code: "PS",
  dial_code: "+970",
  flag: "🇵🇸",
  mask: "99-999-9999",
  name: "Palestinian Territory, Occupied"
}, {
  code: "PA",
  dial_code: "+507",
  flag: "🇵🇦",
  mask: "999-9999",
  name: "Panama"
}, {
  code: "PG",
  dial_code: "+675",
  flag: "🇵🇬",
  mask: "(999)99-999",
  name: "Papua New Guinea"
}, {
  code: "PY",
  dial_code: "+595",
  flag: "🇵🇾",
  mask: "(999)999-999",
  name: "Paraguay"
}, {
  code: "PE",
  dial_code: "+51",
  flag: "🇵🇪",
  mask: "(999)999-999",
  name: "Peru"
}, {
  code: "PH",
  dial_code: "+63",
  flag: "🇵🇭",
  mask: "(999)999-9999",
  name: "Philippines"
}, {
  code: "PL",
  dial_code: "+48",
  flag: "🇵🇱",
  mask: "(999)999-999",
  name: "Poland"
}, {
  code: "PT",
  dial_code: "+351",
  flag: "🇵🇹",
  mask: "99-999-9999",
  name: "Portugal"
}, {
  code: "QA",
  dial_code: "+974",
  flag: "🇶🇦",
  mask: "9999-9999",
  name: "Qatar"
}, {
  code: "RO",
  dial_code: "+40",
  flag: "🇷🇴",
  mask: "99-999-9999",
  name: "Romania"
}, {
  code: "RU",
  dial_code: "+7",
  flag: "🇷🇺",
  mask: "(999)999-99-99",
  name: "Russia"
}, {
  code: "RW",
  dial_code: "+250",
  flag: "🇷🇼",
  mask: "(999)999-999",
  name: "Rwanda"
}, {
  code: "RE",
  dial_code: "+262",
  flag: "🇷🇪",
  mask: "99999-9999",
  name: "Reunion"
}, {
  code: "SH",
  dial_code: "+290",
  flag: "🇸🇭",
  mask: "9999",
  name: "Saint Helena, Ascension and Tristan Da Cunha"
}, {
  code: "KN",
  dial_code: "+1869",
  flag: "🇰🇳",
  mask: "999-9999",
  name: "Saint Kitts and Nevis"
}, {
  code: "LC",
  dial_code: "+1758",
  flag: "🇱🇨",
  mask: "999-9999",
  name: "Saint Lucia"
}, {
  code: "VC",
  dial_code: "+1784",
  flag: "🇻🇨",
  mask: "999-9999",
  name: "Saint Vincent and the Grenadines"
}, {
  code: "WS",
  dial_code: "+685",
  flag: "🇼🇸",
  mask: "99-9999",
  name: "Samoa"
}, {
  code: "SM",
  dial_code: "+378",
  flag: "🇸🇲",
  mask: "9999-999999",
  name: "San Marino"
}, {
  code: "ST",
  dial_code: "+239",
  flag: "🇸🇹",
  mask: "99-99999",
  name: "Sao Tome and Principe"
}, {
  code: "SA",
  dial_code: "+966",
  flag: "🇸🇦",
  mask: "9-999-9999",
  name: "Saudi Arabia"
}, {
  code: "SN",
  dial_code: "+221",
  flag: "🇸🇳",
  mask: "99-999-9999",
  name: "Senegal"
}, {
  code: "RS",
  dial_code: "+381",
  flag: "🇷🇸",
  mask: "99-999-9999",
  name: "Serbia"
}, {
  code: "SC",
  dial_code: "+248",
  flag: "🇸🇨",
  mask: "9-999-999",
  name: "Seychelles"
}, {
  code: "SL",
  dial_code: "+232",
  flag: "🇸🇱",
  mask: "99-999999",
  name: "Sierra Leone"
}, {
  code: "SG",
  dial_code: "+65",
  flag: "🇸🇬",
  mask: "9999-9999",
  name: "Singapore"
}, {
  code: "SK",
  dial_code: "+421",
  flag: "🇸🇰",
  mask: "(999)999-999",
  name: "Slovakia"
}, {
  code: "SI",
  dial_code: "+386",
  flag: "🇸🇮",
  mask: "99-999-999",
  name: "Slovenia"
}, {
  code: "SB",
  dial_code: "+677",
  flag: "🇸🇧",
  mask: "99999",
  name: "Solomon Islands"
}, {
  code: "SO",
  dial_code: "+252",
  flag: "🇸🇴",
  mask: "9-999-999",
  name: "Somalia"
}, {
  code: "ZA",
  dial_code: "+27",
  flag: "🇿🇦",
  mask: "99-999-9999",
  name: "South Africa"
}, {
  code: "SS",
  dial_code: "+211",
  flag: "🇸🇸",
  mask: "99-999-9999",
  name: "South Sudan"
}, {
  code: "ES",
  dial_code: "+34",
  flag: "🇪🇸",
  mask: "(999)999-999",
  name: "Spain"
}, {
  code: "LK",
  dial_code: "+94",
  flag: "🇱🇰",
  mask: "99-999-9999",
  name: "Sri Lanka"
}, {
  code: "SD",
  dial_code: "+249",
  flag: "🇸🇩",
  mask: "99-999-9999",
  name: "Sudan"
}, {
  code: "SR",
  dial_code: "+597",
  flag: "🇸🇷",
  mask: "999-999",
  name: "Suriname"
}, {
  code: "SZ",
  dial_code: "+268",
  flag: "🇸🇿",
  mask: "99-99-9999",
  name: "Eswatini"
}, {
  code: "SE",
  dial_code: "+46",
  flag: "🇸🇪",
  mask: "99-999-9999",
  name: "Sweden"
}, {
  code: "CH",
  dial_code: "+41",
  flag: "🇨🇭",
  mask: "99-999-9999",
  name: "Switzerland"
}, {
  code: "SY",
  dial_code: "+963",
  flag: "🇸🇾",
  mask: "99-9999-999",
  name: "Syrian Arab Republic"
}, {
  code: "TW",
  dial_code: "+886",
  flag: "🇹🇼",
  mask: "9999-9999",
  name: "Taiwan"
}, {
  code: "TJ",
  dial_code: "+992",
  flag: "🇹🇯",
  mask: "99-999-9999",
  name: "Tajikistan"
}, {
  code: "TZ",
  dial_code: "+255",
  flag: "🇹🇿",
  mask: "99-999-9999",
  name: "Tanzania, United Republic of Tanzania"
}, {
  code: "TH",
  dial_code: "+66",
  flag: "🇹🇭",
  mask: "99-999-999",
  name: "Thailand"
}, {
  code: "TL",
  dial_code: "+670",
  flag: "🇹🇱",
  mask: "789-99999",
  name: "Timor-Leste"
}, {
  code: "TG",
  dial_code: "+228",
  flag: "🇹🇬",
  mask: "99-999-999",
  name: "Togo"
}, {
  code: "TK",
  dial_code: "+690",
  flag: "🇹🇰",
  mask: "9999",
  name: "Tokelau"
}, {
  code: "TO",
  dial_code: "+676",
  flag: "🇹🇴",
  mask: "99999",
  name: "Tonga"
}, {
  code: "TT",
  dial_code: "+1868",
  flag: "🇹🇹",
  mask: "999-9999",
  name: "Trinidad and Tobago"
}, {
  code: "TN",
  dial_code: "+216",
  flag: "🇹🇳",
  mask: "99-999-999",
  name: "Tunisia"
}, {
  code: "TR",
  dial_code: "+90",
  flag: "🇹🇷",
  mask: "(999)999-9999",
  name: "Turkey"
}, {
  code: "TM",
  dial_code: "+993",
  flag: "🇹🇲",
  mask: "9-999-9999",
  name: "Turkmenistan"
}, {
  code: "TC",
  dial_code: "+1649",
  flag: "🇹🇨",
  mask: "999-9999",
  name: "Turks and Caicos Islands"
}, {
  code: "TV",
  dial_code: "+688",
  flag: "🇹🇻",
  mask: "29999",
  name: "Tuvalu"
}, {
  code: "UG",
  dial_code: "+256",
  flag: "🇺🇬",
  mask: "(999)999-999",
  name: "Uganda"
}, {
  code: "UA",
  dial_code: "+380",
  flag: "🇺🇦",
  mask: "(99)999-99-99",
  name: "Ukraine"
}, {
  code: "AE",
  dial_code: "+971",
  flag: "🇦🇪",
  mask: "9-999-9999",
  name: "United Arab Emirates"
}, {
  code: "UY",
  dial_code: "+598",
  flag: "🇺🇾",
  mask: "9-999-99-99",
  name: "Uruguay"
}, {
  code: "UZ",
  dial_code: "+998",
  flag: "🇺🇿",
  mask: "99-999-9999",
  name: "Uzbekistan"
}, {
  code: "VU",
  dial_code: "+678",
  flag: "🇻🇺",
  mask: "99999",
  name: "Vanuatu"
}, {
  code: "VE",
  dial_code: "+58",
  flag: "🇻🇪",
  mask: "(999)999-9999",
  name: "Venezuela, Bolivarian Republic of Venezuela"
}, {
  code: "VN",
  dial_code: "+84",
  flag: "🇻🇳",
  mask: "(999)9999-999",
  name: "Vietnam"
}, {
  code: "VG",
  dial_code: "+1284",
  flag: "🇻🇬",
  mask: "999-9999",
  name: "Virgin Islands, British"
}, {
  code: "VI",
  dial_code: "+1340",
  flag: "🇻🇮",
  mask: "999-9999",
  name: "Virgin Islands, U.S."
}, {
  code: "WF",
  dial_code: "+681",
  flag: "🇼🇫",
  mask: "99-9999",
  name: "Wallis and Futuna"
}, {
  code: "YE",
  dial_code: "+967",
  flag: "🇾🇪",
  mask: "99-999-999",
  name: "Yemen"
}, {
  code: "ZM",
  dial_code: "+260",
  flag: "🇿🇲",
  mask: "99-999-9999",
  name: "Zambia"
}, {
  code: "ZW",
  dial_code: "+263",
  flag: "🇿🇼",
  mask: "9-999999",
  name: "Zimbabwe"
},
{
  code: "GB",
  dial_code: "+44",
  flag: "🇬🇧",
  name: "United Kingdom",
  mask: "9999999999",
},
{
  code: "PR",
  dial_code: "+1939",
  flag: "🇵🇷",
  name: "Puerto Rico",
  mask: "9999999999",
}].map((elem) => {
  return {
    ...elem,
    mask: elem.mask ? elem.mask?.replaceAll('9', '#') : "##########",
    label: `${elem.flag} ${elem.name} (${elem.dial_code})`
  }
});
