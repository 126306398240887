<template>
  <div v-if="copied" class="text-success">
    <span class="fas fa-check"></span>
    <small class=""> Copiado!</small>
  </div>
  <button v-else class="btn btn-sm btn-white lift border-0" @click="copy">
    <slot v-if="$slots.icon" name="icon"></slot>
    <PhIcons v-else name="share" :size="20" />
  </button>
</template>

<script>
export default {
  name: "CopyText",
  props: {
    string: String,
  },
  data() {
    return {
      copied: false
    }
  },
  methods: {
    copy() {
      if (this.copied) { return }
      this.copied = true
      // this.$set(this, 'copied', true)
      setTimeout(() => {
        this.copied = false
        // this.$set(this, 'copied', false)
      }, 2000);
      navigator.clipboard.writeText(this.string);
    }
  }
};
</script>
