<template>
  <template v-if="loadingItems"></template>
  <template v-else>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="sticky-top">
          <div class="card card-body">
            <div class="row px-4 my-3 text-primary">
              <div class="ps-0 col d-flex align-items-center">
                <span @click="handleChangeMonth(false)"
                  class="px-3 fas fa-medium-size fa-chevron-circle-left text-secondary cursor-pointer"
                  :class="{ 'text-primary25': selectedMonthPosition == 0 }"></span>
                <h5 class="text-subtitle m-0">{{ currentMonthString }}</h5>
                <span @click="handleChangeMonth(true)"
                  class="px-3 fas fa-medium-size fa-chevron-circle-right text-secondary cursor-pointer"
                  :class="{ 'text-primary25': selectedMonthPosition == months.length - 1 }"></span>
              </div>
              <div class="col-auto d-flex align-items-center">
                <h5 @click="todayPressed" class=" text-subtitle text-secondary m-0 cursor-pointer">Hoje</h5>
              </div>
            </div>
            <hr>
            <div class="row px-lg-5 pt-3">
              <div class="col align-items-center d-flex justify-content-center text-primary py-1 px-0 px-md-2"
                v-for="week in weekDays">
                <span>{{ week }}</span>
              </div>
            </div>
            <div class="row px-lg-5" v-for="index in 6">
              <div class="align-items-center col d-flex justify-content-center p-3 text-primary px-0 px-md-2"
                v-for="day in currentMonth?.month[index]">
                <div
                  class="rounded-circle day-container d-flex justify-content-center align-items-center cursor-pointer text-subtitle"
                  :class="`${getColor(day)}`" @click="handleDateSelected(day)">
                  <span class="">{{ day.day }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card card-body">
          <div class="row">
            <div class="col-12">
              <h5 class="text-header text-primary">Horários disponíveis</h5>
            </div>
            <LoadingContainer v-if="loadingTimes" />
            <template v-else>
              <div class="col-12">
                <div class="list-group list-group-flush my-n3 pb-5">
                  <div v-if="allowOnline" class="list-group-item cursor-pointer" @click="handleTimeSelected('online')">
                    <div class="row align-items-center">
                      <div class="col pe-0">
                        <h5 class="mb-0 text-primary">
                          Agendar para agora <span class="badge bg-success ms-2">Disponível agora</span>
                        </h5>
                      </div>
                      <div class="col-auto">
                        <span>
                          <span v-if="selectedTime == 'online'" class="fas fa-check-square text-secondary"></span>
                          <span v-else class="far fa-square text-muted"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <template v-if="times.length">
                    <div class="list-group-item cursor-pointer" v-for="time in times"
                      @click="handleTimeSelected(time.date)">
                      <div class="row align-items-center">
                        <div class="col pe-0">
                          <h5 class="mb-0 text-primary">
                            {{ time.formattedDate.capitalizeFirst() }}
                          </h5>
                        </div>
                        <div class="col-auto">
                          <span>
                            <span v-if="selectedTime == time.date" class="fas fa-check-square text-secondary"></span>
                            <span v-else class="far fa-square text-muted"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div v-else>
                    <h5 class="text-primary50 pt-4">Nenhum horário disponível</h5>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import CalendarComponentController from './CalendarComponentController'
export default CalendarComponentController
</script>
<style scoped>
/* tamanho de tela lg de acordo com o bootstrap */
.day-container {
  height: 40px;
  width: 40px;
}

@media (max-width: 1275px) {
  .day-container {
    height: 20px;
    width: 20px;
    font-size: 0.8rem !important;
  }
}
</style>
