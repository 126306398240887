<template>
  <div>
    <div class="d-flex justify-content-between">
      <label v-if="label" class="form-label text-muted"> {{ label }} <i v-if="required" class="text-danger">*</i>
      </label>
      <slot name="labelContentEnd"></slot>
    </div>
    <div class="autocomplete-input-container position-relative">
      <input class="form-control" type="text" :placeholder="placeholder" :style="inputStyle" v-model.trim="model"
        @focus="showResults = true" @focusout="hideResults" :class="{ 'is-invalid': validationModel.$invalid }" />
      <div class="invalid-feedback">
        <small>{{ errorMsg }}</small>
      </div>
      <div v-if="showResults" class="bg-white position-absolute select-custom w-100" style="z-index: 10">
        <ul class="list-group rounded-bottom" id="results-list">
          <li class="list-group-item text-muted bg-light">
            Selecione uma opção ou uma nova sera criada
          </li>
          <li class="list-group-item cursor-pointer" v-for="(result) in filteredResults"
            @click="handleItemSelected(result)">
            {{ result.title }}
          </li>
        </ul>
      </div>
      <!-- <table class="table table-bordered table-hover position-absolute search-result w-auto mt-2 bg-white"
        style="min-width: 300px; z-index: 10;" v-if="showResults">
        <tbody>
          <tr class="cursor-pointer" v-for="(result) in filteredResults" @click="handleItemSelected(result)">
            <td>{{ result.title }}</td>
          </tr>
        </tbody>
      </table> -->
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
import AutocompleteInputController from './AutocompleteInputController'
export default AutocompleteInputController
</script>
<style>
.select-custom .list-group {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.select-custom .list-group-item {
  padding: 12px 20px;
  border-color: #d9d9d9;
}

.select-custom .list-group-item:hover {
  background-color: #dee6e1;
}

.no-border {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
