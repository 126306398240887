<template>
  <LoadingContainer v-if="loadingItems" />
  <template v-else>
    <div class="border mt-3 p-4 rounded-5 text-primary" v-for="item in items">
      <div class="cursor-pointer" @click="handleSelectCard(item)">
        <div class="row cursor-pointer">
          <div class="col">
            <h5 class="fw-bold mb-0">
              <PhIcons name='wallet' :size="23" />
              <span class="ms-3">Cartão de crédito</span>
            </h5>
          </div>
          <div class="col-auto d-flex align-items-center">
            <span v-if="item.card_id == userSession?.favorite_card?.card_id"
              class="fas fa-medium-size fa-check-circle text-secondary"></span>
            <span v-else class="far fa-medium-size fa-circle text-primary25"></span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <h5 class="mb-0">
              <span class="">**** **** **** {{ item.last_digits }}</span>
            </h5>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasRemove">
        <div class="col">
          <span class="btn btn-link ps-0 text-primary50 pb-0" @click="handleRemove(item)">
            <span class="far fa-trash-can fa-check-circle me-1"></span>
            Remover
          </span>
        </div>
      </div>
    </div>
    <div class="border mt-3 p-4 rounded-5 text-primary cursor-pointer" @click="handleAdd">
      <div class="row">
        <div class="col">
          <h5 class="fw-bold mb-0">
            <PhIcons name='wallet' :size="23" />
            <span class="ms-3">Adicionar novo cartão</span>
          </h5>
        </div>
        <div class="col text-end text-subtitle">
          <span class="fas fa-chevron-right"></span>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import PaymentMethodListController from "./PaymentMethodListController";
export default PaymentMethodListController;
</script>
<style scoped></style>
