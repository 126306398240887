import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useMainStore } from '@/store'
import paths from './paths';
const routes: Array<RouteRecordRaw> = [
  {
    path: `${paths.login}`,
    name: 'Login',
    component: () => import('@/views/Login/Login.vue')
  },
  {
    path: `${paths.searchPsi}`,
    name: 'SearchPsi',
    alias: '/',
    component: () => import('@/views/SearchPsi/SearchPsi.vue'),
  },
  {
    path: `${paths.home}`,
    name: 'Home',
    component: () => import('@/views/Home/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.appointments.list}`,
    name: 'Appointments',
    component: () => import('@/views/Appointments/Appointments.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.register.base,
    name: 'Register',
    component: () => import('@/views/Register/Register.vue'),
    children: [
      {
        path: `${paths.register.children.info}`,
        name: 'RegisterInfo',
        component: () => import('@/views/Register/RegisterInfo.vue'),
      },
      {
        path: `${paths.register.children.nickname}`,
        name: 'RegisterNickname',
        component: () => import('@/views/Register/RegisterNickname.vue'),
      },
    ],
  },
  {
    path: `${paths.settings}/:id`,
    name: 'Settings',
    props: true,
    component: () => import('@/views/Settings/Settings.vue'),
  },
  {
    path: `${paths.chats}/:id?`,
    props: true,
    name: 'Chats',
    component: () => import('@/views/Chats/Chats.vue'),
  },
  {
    path: `${paths.more}/:id?`,
    name: 'Mais',
    component: () => import('@/views/More/More.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.scheduleDate}`,
    name: 'AppointmentScheduleDate',
    component: () => import('@/views/AppointmentScheduleDate/AppointmentScheduleDate.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.custom_schedule}/:date/:psiId`,
    name: 'CustomSchedule',
    props: true,
    component: () => import('@/views/CustomSchedule/CustomSchedule.vue'),
  },
  {
    path: `${paths.checkout}`,
    name: 'Checkout',
    component: () => import('@/views/Checkout/Checkout.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.psiDetail}/:id?`,
    name: 'PsiDetail',
    props: true,
    component: () => import('@/views/PsiDetail/PsiDetail.vue'),
  },
  {
    path: `${paths.appointments.detail}/:id`,
    name: 'Appointment Detail',
    props: true,
    component: () => import('@/views/Appointments/AppointmentDetail/AppointmentDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.callScreen}/:callId`,
    name: 'Live Call',
    props: true,
    component: () => import('@/views/LiveCallScreen/LiveCallScreen.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.passwordReset}`,
    name: 'Password Reset',
    component: () => import('@/views/PasswordReset/PasswordReset.vue'),
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: () => import('@/views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useMainStore()
  const isLoggedIn = !!store.session;
  if (to.path == '/') {
    if (isLoggedIn) {
      next(paths.home);
    } else {
      next(paths.searchPsi);
    }
  } else {
    if (to.meta.requiresAuth && !isLoggedIn) {
      next(paths.login);
    } else {
      next();
    }
  }
});

export default router
