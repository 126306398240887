import { useMainStore } from "@/store"
import NetworkService, { Endpoint } from "./NetworkService"

export default class RescheduleService {
	network
	constructor() {
		this.network = new NetworkService()
	}
	static RESCHEDULE_STATUS = {
		PENDING: "pending",
		CANCELED: "canceled",
		ACCEPTED: "accepted",
		REJECTED: "rejected"
	}
	update(rescheduleId: any, params: any) {
		return this.network.put(`${Endpoint.reschedule}/${rescheduleId}`, params)
	}
	create(params: any) {
		return this.network.post(`${Endpoint.reschedule}`, params)
	}
	getFormattedRescheduleStatus(statusName: string, madeByPsi: boolean) {
		switch (statusName) {
			// 'pending','canceled','accepted','rejected'
			case RescheduleService.RESCHEDULE_STATUS.PENDING:
				return {
					name: "Reagendamento pendente",
					color: 'text-primary',
					bgColor: 'bg-primary05',
					icon: 'fas fa-clock',
					text: `encontra-se aguardando confirmação por ${!madeByPsi ? 'parte do psi' : 'você'}`
				}
			case RescheduleService.RESCHEDULE_STATUS.REJECTED:
				return {
					name: "Reagendamento rejeitado",
					color: 'text-warning',
					bgColor: 'bg-warning05',
					icon: 'fas fa-times-circle',
					text: `não foi confirmado por ${!madeByPsi ? 'parte do psi' : 'você'}`
				}
			case RescheduleService.RESCHEDULE_STATUS.CANCELED:
				return {
					name: "Reagendamento cancelado",
					color: 'text-danger',
					bgColor: 'bg-danger05',
					icon: 'fas fa-times-circle',
					text: 'foi cancelado'
				}
			case RescheduleService.RESCHEDULE_STATUS.ACCEPTED:
				return {
					name: "Reagendamento confirmado",
					color: 'text-success',
					bgColor: 'bg-success05',
					icon: 'fas fa-check-circle',
					text: `foi confirmado por ${!madeByPsi ? 'parte do psi' : 'você'}`
				}
			default:
				return {
					name: "Sem status",
					color: 'primary'
				}
		}
	}

}
