// import { environment } from "../environment";
// import * as RootNavigation from "@/routeStacks/RootNavigation";
// import { store } from "@/stores/store";
import { languages } from "@/utils/languages";
// import { getDeviceInfo } from "@/utils/mixins";
import { useMainStore } from "@/store";
import NetworkService, { Endpoint } from "./NetworkService";
// import { phoneCodes } from '@/utils/phoneCodes';

import { ILogin } from "@/interfaces";
import { phoneCodes } from "@/utils/phoneCodes";
import AppointmentService from "./AppointmentService";
export const avatars: any = {
  1: require("../assets/img/avatars/1.png"),
  2: require("../assets/img/avatars/2.png"),
  3: require("../assets/img/avatars/3.png"),
  4: require("../assets/img/avatars/4.png"),
  5: require("../assets/img/avatars/5.png"),
  6: require("../assets/img/avatars/6.png"),
  7: require("../assets/img/avatars/7.png"),
  8: require("../assets/img/avatars/8.png"),
  9: require("../assets/img/avatars/9.png"),
  10: require("../assets/img/avatars/10.png"),
  11: require("../assets/img/avatars/11.png"),
  12: require("../assets/img/avatars/12.png"),
  13: require("../assets/img/avatars/13.png"),
  14: require("../assets/img/avatars/14.png"),
  15: require("../assets/img/avatars/15.png"),
  16: require("../assets/img/avatars/16.png"),
  17: require("../assets/img/avatars/17.png"),
  18: require("../assets/img/avatars/18.png"),
  19: require("../assets/img/avatars/19.png"),
  20: require("../assets/img/avatars/20.png"),
  21: require("../assets/img/avatars/21.png"),
};
export default class UserService {
  network
  appointmentService
  constructor() {
    this.network = new NetworkService();
    this.appointmentService = new AppointmentService()
  }
  async login(params: ILogin) {
    var login = await this.network.post(Endpoint.login, params);
    if (login.data.type != "user") {
      throw { message: "Este email de acesso não pertence a um paciente" };
    } else {
      const store = useMainStore()
      store.saveAuthToken(login.access_token || '')
      let userData = await this.prepareUserData(login.data.id);
      return userData;
    }
  }

  async prepareUserData(userId: any) {
    let data = null;
    try {
      const user = await this.getUser(userId);
      if (user.status) {
        data = this.getFormattedUser(user.data)
      }
      if (data.partnership) {
        data.partnership.info.code = data.partnership?.partnership_code?.code
      }
    } catch (e) {
      console.log("prepareUserData", e);
    }
    return data;
  }
  async register() {
    const store = useMainStore()
    let params = { ...store.register_info, type: 'user' }
    console.log(params)
    if (params.redirectTo) {
      delete (params.redirectTo)
    }
    const res: any = await this.network.post(Endpoint.users, params);
    store.saveAuthToken(res.access_token)
    let userData = await this.prepareUserData(res.data.id);
    store.updateState({ register_info: null })
    return userData
  }
  update(id: string | number, params: any) {
    return this.network.put(`${Endpoint.users}/${id}`, params);
  }
  listNotifications(params: any) {
    return this.network.get(Endpoint.notifications, params);
  }
  sendRecoveryPasswordEmail(email: string) {
    return this.network.post(Endpoint.recoveryPassword, { email });
  }
  async syncSession() {
    const store = useMainStore()
    const data = await this.prepareUserData(
      store.session?.id
    );
    if (data) {
      store.updateSession({
        ...store.session,
        ...data,
      });
      console.log({
        ...store.session,
        ...data,
      })
    }
  }
  deleteAccount() {
    const store = useMainStore()
    const id = store.session?.id;
    return this.network.delete(`${Endpoint.users}/${id}`);
  }
  // logout() {
  //   this.sessionService.destroySession();
  // }
  // checkEmail(email) {
  //   return this.network.post(this.network.endpoints.checkEmail, { email });
  // }
  getAvatar(userAvatar: any) {
    return avatars[userAvatar] || require("../assets/img/avatars/1.png");
  }

  async getUser(id: string) {
    if (isNaN(Number(id))) {
      // se o cast pra number nao for um numero, é pq o id é uma string, logo, só pode ser um slug 
      const res = await this.getUserBySlug(id);
      return this.network.get(`${Endpoint.users}/${res.data.id}`);
    }
    return this.network.get(`${Endpoint.users}/${id}`);
  }
  getUserBySlug(slug: string) {
    return this.network.get(`${Endpoint.usersSlug}/${slug}`);
  }
  getSettings(key: string) {
    return this.network.get(`api/${key}`);
  }

  listHomeInfo = async () => {
    let response: any = {}
    const res: any = await this.network.get(`${Endpoint.homeInfo}`);
    if (res.status) {
      response.ongoing = res.in_progress || null
      response.scheduled_appointments = res.agendado.map((elem: any) => this.appointmentService.prepareAppointment(elem)) || []
      response.my_psis = res.recent_psy?.map((r: any) => this.getFormattedPsi(r.psychologist)).filter((p: any) => !p.deleted_at) || []
    }
    return response
  };
  listPsiRatings(params: any) {
    return this.network.get(Endpoint.callRating, params);
  }
  listHomeVideos = () => {
    return this.network.get(`${Endpoint.homeVideos}`);
  };
  activatePartnership = (partner_code: string) => {
    return this.network.post(`${Endpoint.partnership}`, { partner_code });
  };
  removePartnership = (partnershipId: number | string) => {
    return this.network.delete(`${Endpoint.partnership}/${partnershipId}`);
  };

  getFormattedUser(item: any) {
    try {
      item.country_code = item.country_code || '+55'
      item.formatted_image = this.getAvatar(item.profile_image)
      item.formatted_phone = `${phoneCodes.find(r => r.dial_code == item.country_code)?.flag}${item.country_code} ${this.getMaskedPhone(item.phone, item.country_code)}`
    } catch (e) {
      throw (e)
    } finally {
      return item;
    }
  }

  getFormattedPsi(psi: any) {
    try {
      psi.isPremium = psi.subscription && ["trialing", "active"].includes(psi.subscription?.status);
      psi.formatted_occupations = psi.occupations;
      psi.formatted_approaches = psi.approaches?.filter((r: any) => r.info);
      psi.canHaveFirstAppointment = psi.first_appointment && psi.appointments_with_this_psy_count == 0;
      psi.formatted_rating = (psi.avg_rating || 0).toFixed("1");

      if (psi.profile_image) {
        psi.formatted_profile_image = `${process.env.VUE_APP_BASE_IMG_URL}${psi.profile_image}`
      } else {
        psi.formatted_profile_image = require('@/assets/img/avatars/1.png')
      }
      if (psi.deleted_at) {
        psi.formatted_profile_image = require('@/assets/img/unavailable_user_square.png')
      }
      psi.formatted_exp = `${psi?.years_exp} ${psi?.years_exp > 1 ? "anos" : "ano"} de experiência`
      if (psi.languages) {
        psi.languages.map((elem: any) => {
          elem.detail.flag = languages[elem.detail.code].flag;
        });
      }
      if (psi.intro_video_link) {
        psi.formatted_intro_video_link = `https://www.youtube.com/embed/${psi.intro_video_link}`
      }
      if (psi.latest_ratings?.length) {
        psi.formatted_latest_ratings = psi.latest_ratings.filter((elem: any) => elem.user != null).map((elem: any) => {
          return {
            ...elem,
            img: this.getAvatar(elem.user.profile_image),
            initials: `${elem.user.name.charAt(0)}.`
          }
        })
      }
    } catch (e) {
      console.log('getFormattedPsi', e);
    } finally {
      return psi;
    }
  }
  getMaskedPhone(phone: string, countryCode: string) {
    try {
      let p: any = phone
      if (p && countryCode) {
        let countryMask: any = phoneCodes.find(elem => elem.dial_code == countryCode)
        if (countryMask?.mask) {
          countryMask = countryMask.mask.replaceAll('9', '#')
          p = p.clear().conformToPattern(countryMask)
        }
      }
      return p
    } catch (e) {
      console.log(e)
      return phone
    }
  }
  resetPassword(params: any) {
    return this.network.post(`${Endpoint.passwordReset}`, params);
  }
  checkPartnership(psiId: number | string) {
    return this.network.post(Endpoint.checkPartnership, { psychologist_id: psiId });
  }

}
