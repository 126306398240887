import moment from "moment";
String.prototype.capitalizeFirst = function () {
  let res = this;
  try {
    res = this.charAt(0).toUpperCase() + this.slice(1);
  } catch (e) {
    console.log(e);
  } finally {
    return res;
  }
};
String.prototype.limitString = function (limit = 40) {
  let res = this;
  try {
    res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this;
  } catch (e) {
    console.log("erro", e);
    return res;
  } finally {
    return res;
  }
};

String.prototype.clear = function () {
  let res = this;
  try {
    res = this.replace(/[^\w\s]/gi, "")
      .replace(" ", "")
      .replace(/[_-]/g, "");
  } catch (e) {
    return res;
  } finally {
    return res;
  }
};

/**
 * @description Formata a data conforme o mode que passa.
 *   - 1 - hoje, ontem ou data
 *   - 2 - 12 de Jun
 *   - 3 - 12/07 - Quinta feira às 16:43
 *   - 4 - 12/07 - Quinta feira
 *   - 5 - Julho de 2022
 *   - 6 - Dia 23/07 | Segunda-feira
 *   - 7 - 10 de Junho de 2023
 *   - 8 - 19h00 as 19h50 ( adiciona 50 min )
 *   - 9 - nas quintas-feiras as 19h50
 *   - 10 - 23 às 16h43
 *   - 11 - 23/11
 * @param {number} mode modo de formatacao da data (default = 1)
 *
 */

String.prototype.toFormattedDate = function (
  mode = 1,
  showTodayAsString = false
) {
  let res = this;
  if (!res) return "";
  try {
    const date = new Date(this);
    switch (mode) {
      case 1:
        // hoje, ontem ou data
        if (moment(date).isSame(moment(), "day")) {
          //mesmo dia
          res = showTodayAsString ? "Hoje" : moment(date).format("HH:mm");
        } else if (moment(date).isSame(moment().add(-1, "days"), "day")) {
          res = "Ontem";
        } else {
          res = moment(date).format("DD/MM/YYYY");
        }
        break;
      case 2:
        // 12 de Jun
        res = `${moment(date).format("DD [de] MMM")}`;
        break;
      case 3:
        // 12/07 - Quinta feira às 16:43
        res = `${moment(date).format("DD/MM[ - ]dddd[ às ]HH:mm")}`;
        break;
      case 4:
        // 12/07 - Quinta feira
        const m = moment(date).format("dddd").capitalizeFirst();
        res = `${moment(date).format("DD/MM")} - ${m}`;
        break;
      case 5:
        // Julho de 2022
        res = `${moment(date).format("MMMM [de] YYYY")}`.capitalizeFirst();
        break;
      case 6:
        // Dia 23/07 | Segunda-feira
        res = `Dia ${moment(date).format("DD/MM")} | ${`${moment(date).format(
          "dddd"
        )}`.capitalizeFirst()}`;
        break;
      case 7:
        // 10 de Junho de 2023
        res = `${moment(date).format("DD")} de ${`${moment(date).format(
          "MMMM [de] YYYY"
        )}`.capitalizeFirst()}`;
        break;
      case 8:
        // 19h00 as 19h50
        res = `${moment(date).format("HH[h]mm")} às ${moment(date).add(50, 'minutes').format("HH[h]mm")}`
        break;
      case 9:
        //nas quintas-feiras as 19h50
        const weekDay = moment(date).day()
        const d = moment(date).format('dddd')
        let day = d.split('-')
        //dia no plural
        day = day.length > 1 ? `${day[0]}s-${day[1]}s` : `${day[0]}s`
        const acronym = [6, 0].includes(weekDay) ? 'aos' : 'nas'
        res = `${acronym} ${day} às ${moment(date).format("HH[h]mm")}`
        break;
      case 10:
        // 23 as 19h50
        res = `${moment(date).format("DD [às] HH[h]mm")}`
        break;
      case 11:
        // 23/11
        res = `${moment(date).format("DD/MM")}`
        break;
    }
  } catch (e) {
    console.log(e)
    return res;
  } finally {
    return res;
  }
};


String.prototype.conformToPattern = function (ptrn = '') {
  let res = this
  let newString = ''
  let stringIndex = 0
  try {
    let pattern = ptrn || Array(this.length).fill('#')
    const patternCount = pattern.length
    for (var i = 0; i < patternCount; i++) {
      if (this[stringIndex]) {
        if (pattern[i] == '#') {
          newString = `${newString}${this[stringIndex]}`
          stringIndex++

        } else {
          newString = `${newString}${pattern[i]}`
        }
      }
    }
  } catch (e) {
    return res
  } finally {
    return newString
  }
}
String.prototype.replaceAllOccurences = function (search, replaceWith) {
  let res = this
  const searchRegExp = new RegExp(search, 'g')
  try {
    const result = res?.replace(searchRegExp, replaceWith);
    return result
  } catch (e) {
    return res
  }
}
