<template>
  <div>
    <button
      hidden
      :id="`${id}_offcanvas_button`"
      type="button"
      data-bs-toggle="offcanvas"
      :data-bs-target="`#${id}`"
      aria-controls="staticBackdrop"
    ></button>
    <div
      class="offcanvas offcanvas-end"
      data-bs-backdrop="static"
      tabindex="-1"
      :id="id"
      aria-labelledby="staticBackdropLabel"
    >
      <div class="offcanvas-header justify-content-center">
        <i
          class="fa-solid fa-chevron-circle-left display-5 text-primary25 cursor-pointer position-absolute"
          style="left: 20px"
          @click="offcanvasClosed"
        />

        <p class="offcanvas-title text-header" id="staticBackdropLabel">
          {{ title }}
        </p>
        <!-- <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="offcanvasClosed"
        ></button> -->
      </div>
      <div class="offcanvas-body" :class="bodyClass">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Title",
    },
    id: {
      type: String,
      default: "",
    },
    bodyClass: {
      type: String,
      default: "",
    },
  },
  emits: ["offcanvasClosed"],
  methods: {
    offcanvasClosed() {
      toggleOffcanvas(this.id);
      this.$emit("offcanvasClosed");
    },
  },
});

export const toggleOffcanvas = (id: string) => {
  const button = document.getElementById(`${id}_offcanvas_button`);
  if (button) {
    button?.click();
  }
};
</script>
