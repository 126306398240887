import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAgjhMXLwtegBTRMjhAdMLFDARBS9We8RM",
  authDomain: "psiapp-44a08.firebaseapp.com",
  projectId: "psiapp-44a08",
  storageBucket: "psiapp-44a08.appspot.com",
  messagingSenderId: "1084629413864",
  appId: "1:1084629413864:web:cd9988a5bc5361c7307e5d",
  measurementId: "G-74KH2VDPR1"
};
export function startFirebase() {
  if(process.env.VUE_APP_ENV_NAME == 'PROD') {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }
}
