<template>
  <div class="row position-fixed w-100 border-top bottom-actions bg-white">
    <slot name="children"></slot>
  </div>
</template>

<script>
export default {
  props: {
    col: {
      type: String,
      default: 'col-10'
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.bottom-actions {
  bottom: 0;
  left: 12px;
  z-index: 999
}
</style>
