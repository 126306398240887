<template>
  <span v-for="(rating, index) in Array(5).fill('')" v-bind:key="'rating-' + index" class="me-2"
    @click="ratingChanged(index)">
    <span v-if="index + 1 <= ratingCount" class="fas fa-medium-size fa-star text-warning"></span>
    <span v-else class="fas fa-medium-size fa-star text-primary25"></span>
  </span>
</template>

<script>
export default {
  name: "StarRatingComponent",
  props: {
    ratingCount: Number,
    default: 0
  },
  methods: {
    ratingChanged(rating) {
      this.$emit('onRatingChanged', (rating + 1))
    }
  }
};
</script>
