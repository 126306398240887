import { PropType, defineComponent } from 'vue'
export default defineComponent({
  name: 'ConfirmationAlert',
  props: {
    months: {
      type: Array as PropType<any[]>,
      default: [],
    },
    psiId: {
      type: String
    },
    allowOnline: {
      type: Boolean
    }
  },
  mounted() {
    if (this.allowOnline) {
      this.selectedTime = 'online'
    }
    this.selectedDate = this.months[0].firstAvailableDay
  },
  computed: {
    currentMonth(): any {
      return this.months[this.selectedMonthPosition]
    },
    currentMonthString(): string {
      console.log(this.currentMonth.date)
      return this.currentMonth.date.format('YYYY-MM-DD').toFormattedDate(5)
    }
  },
  data() {
    return {
      loadingItems: false,
      loadingTimes: false,
      times: [] as any[],
      selectedTime: '' as any,
      selectedDate: '' as any,
      selectedMonthPosition: 0,
      weekDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
    }
  },
  watch: {
    selectedDate(val) {
      if (this.selectedTime != 'online') {
        this.selectedTime = ''
        this.$emit('onDateChanged', '')
      }
      this.getTimes()
    },
    selectedTime(val) {
      this.$emit('onDateChanged', this.selectedTime)
    }
  },
  methods: {
    handleChangeMonth(plus = true) {
      let newPosition = this.selectedMonthPosition
      if (plus) {
        // esse months ta sendo definido la no service... ta 6 por enquanto. se tiver no ultimo nao faz nada
        if (this.selectedMonthPosition == this.months.length - 1) {
          return
        }
        newPosition++
      } else {
        //se tiver no primeiro e quiser ir pra traz, nao vai
        if (this.selectedMonthPosition == 0) {
          return
        }
        newPosition--
      }
      this.selectedMonthPosition = newPosition
      this.selectedDate = this.months[newPosition].firstAvailableDay
    },
    todayPressed() {
      this.selectedMonthPosition = 0
      this.selectedDate = ''
    },
    handleDateSelected(day: any) {
      if (day.disabled) { return }
      this.selectedDate = day.formattedDate
    },
    async getTimes() {
      try {
        this.loadingTimes = true
        if (!this.selectedDate) {
          this.times = []
        } else {
          const res = await this.$appointmentService.getAvailableTimes(
            this.selectedDate,
            this.psiId
          );
          this.times = res.data
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingTimes = false
      }
    },
    getColor(day: any) {
      if (day.formattedDate == this.selectedDate) {
        return 'text-white bg-primary'
      }
      if (day.isToday) {
        return 'text-primary50 bg-primary05'
      }
      if (day.disabled) {
        return 'text-primary25'
      }
      return ''
    },
    handleTimeSelected(timeAndDate: string) {
      this.selectedTime = timeAndDate
      this.$emit('onDateChanged', timeAndDate)
    }

  },
  destroyed() {
  }
})
