import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-bs-target"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "offcanvas-header justify-content-center" }
const _hoisted_4 = {
  class: "offcanvas-title text-header",
  id: "staticBackdropLabel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      hidden: "",
      id: `${_ctx.id}_offcanvas_button`,
      type: "button",
      "data-bs-toggle": "offcanvas",
      "data-bs-target": `#${_ctx.id}`,
      "aria-controls": "staticBackdrop"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "offcanvas offcanvas-end",
      "data-bs-backdrop": "static",
      tabindex: "-1",
      id: _ctx.id,
      "aria-labelledby": "staticBackdropLabel"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: "fa-solid fa-chevron-circle-left display-5 text-primary25 cursor-pointer position-absolute",
          style: {"left":"20px"},
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.offcanvasClosed && _ctx.offcanvasClosed(...args)))
        }),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["offcanvas-body", _ctx.bodyClass])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 8, _hoisted_2)
  ]))
}