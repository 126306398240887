<template>
  <ConfirmationAlert />
  <NotificationToast />
  <ErrorAlertComponent />
  <AddCreditCardModal />
  <TermsComponent />
  <router-view />
</template>

<script>
import NotificationToast from "@/components/NotificationToast.vue";
import TermsComponent from "@/components/TermsComponent.vue";
import AddCreditCardModal from "@/views/More/AddCreditCardModal/AddCreditCardModal.vue";
import { defineComponent } from "vue";
import ErrorAlertComponent from "@/components/ErrorAlertComponent.vue";
import { useMainStore } from "./store";
import { startFirebase } from "./firebaseinit";
export default defineComponent({
  mounted() {
    if (useMainStore().session) {
      this.$userService.syncSession()
    }
    startFirebase()
  },
  created() {
    console.log('VERSION', `${this.$appString}`)
    document.title = process.env.VUE_APP_APP_NAME;
  },
  components: {
    ErrorAlertComponent,
    NotificationToast,
    AddCreditCardModal,
    TermsComponent
  },
});
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
