import useVuelidate from '@vuelidate/core';
import { helpers, minLength, minValue, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    appointment: {
      type: Object,
    }
  },
  computed: {
    rating(): any {
      return this.appointment?.my_rating
    },
  },
  data() {
    return {
      form: {
        trouble: false,
        description: '',
        call_completed: true,
        // psi_rating: 0,
        testimonial: '',
      },
      submitting: false
    }
  },
  components: {
  },
  methods: {
    handleTrouble(trouble: boolean) {
      this.form.trouble = trouble
    },
    handleCallCompleted(completed: boolean) {
      this.form.call_completed = completed
    },
    handleStarChanged(rating: number) {
      // this.form.psi_rating = rating
    },
    async handleSendFeedback() {
      if (!this.appointment) return
      let values = JSON.parse(JSON.stringify(this.form))
      if (!values.trouble) {
        values.call_completed = true
      }
      if (!values.call_completed) {
        //provavelmente tinha algo aqui, mas no app ta vazio entao so copiei
      }
      values.appointment_id = this.appointment.id
      // console.log(values)
      // return
      try {
        this.submitting = true
        let res = await this.$appointmentService.callRating(values)
        if (res.status) {
          this.$emit('onRatingFinished')
          this.$eventBus.$emit('refreshAppointment')
          this.$eventBus.$emit('showToast', { title: 'Depoimento enviado', msg: `Seu depoimento foi enviada e está sendo analizada` })
        }
      } catch (error: any) {
        this.$eventBus.$emit("showErrorToast", { msg: error.message })
      } finally {
        this.submitting = false
      }
    }
  },
  validations() {
    return {
      form: {
        trouble: {},
        call_completed: {},
        description: this.form.trouble ? {
          required: helpers.withMessage('Informe a descrição do problema técnico', required),
          minLength: helpers.withMessage('Informe uma descrição mais detalhada do problema técnico', minLength(5))
        } : {
          minLength: helpers.withMessage('Informe uma descrição mais detalhada do problema técnico', minLength(5))
        },
        // psi_rating: this.form.call_completed ? {
        //   required: helpers.withMessage('Deixe uma depoimento para o psi', required),
        //   minValue: helpers.withMessage('Deixe uma depoimento para o psi', minValue(1))
        // } : {},
        // psi_rating: {},
        // testimonial: (this.form.call_completed && this.form.psi_rating < 5) || (!this.form.trouble && this.form.psi_rating < 5) ? {
        //   required: helpers.withMessage('Deixe um depoimento para o psi', required),
        //   minLength: helpers.withMessage('Deixe um depoimento mais detalhado para o psi', minLength(5))
        // } : {}
        testimonial: {}
      }
    }
  }
})